const vueTableConfig = {
    vueTableConfig: {
        url: '',
        appendParams: {},
        perPage: 50,
        css: {
            table: {
                tableClass: 'table table-striped table-hover',
                loadingClass: 'loading',
                ascendingIcon: 'glyphicon glyphicon-chevron-up',
                descendingIcon: 'glyphicon glyphicon-chevron-down',
                handleIcon: 'glyphicon glyphicon-menu-hamburger',
            },
            pagination: {
                infoClass: 'pull-left',
                wrapperClass: 'vuetable-pagination pull-right',
                activeClass: 'btn-primary text-white',
                disabledClass: 'disabled',
                pageClass: 'btn btn-border',
                linkClass: 'btn btn-border',
                icons: {
                    first: '',
                    prev: '',
                    next: '',
                    last: '',
                },
            }
        },
        info: {
            css: {
                infoClass: 's',
            },
            infoTemplate: 'Mostrando del {from} al {to}, de {total} registro(s)',
            noDataTemplate: 'No relevant data',
        }
    }
}

export {
    vueTableConfig
}