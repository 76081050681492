import { vueTableConfig } from '@/config/vueTableConfig';
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo';

export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
    },
    data: () => {
        return { 
            ...vueTableConfig,
            "vue-table-loading": false
        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.$refs.paginationInfo.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        refresh() {
            this.$refs.vuetable.refresh();
        },
        transform(data) {
            const retorno = {
                ...data.data,
                links: {
                    pagination: data.data.links
                }
            };
            return retorno;
        }
    }
};