<template>
    <div ref="popupBody" class="popup-modal" v-if="isVisible" @keyup.esc="_handler(false)" tabindex="-1">
        <div class="window py-2">
            <div class="row">
                <!-- <div class="col-md-12">
                    <h2 style="margin-top: 0">{{ title }}</h2>
                </div> -->
                <div class="col-md-12">
                    <p v-html="message"></p>
                </div>
                <div class="col-md-12 m-0 p-0 px-3 pt-2 border-top">
                    <div class="btns">
                        <button class="btn btn-outline-dark" @click="_handler(false)" :disabled="loading">
                            {{ cancelButton }}
                        </button>
                        <button class="btn btn-danger" @click="_handler(true)" v-loading-btn="{loading: loading}">
                            {{ okButton }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ConfirmDialogue',
    data: () => {
        return {
            isVisible: false,
            // title: undefined,
            message: undefined, // Main text content
            okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
            cancelButton: 'Cerrar', // text for cancel button
            // Private variables
            closeOnConfirm: false,
            loading: false,
            callback: (confirm) => {
                console.log('default confirm', confirm);
            }
        };
    },
    methods: {
        show(opts = {}) {
            this.message = opts.message;
            this.okButton = opts.okButton;
            if(opts.callback) {
                this.callback = opts.callback;
            }
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            this.isVisible = true;
            this.$nextTick(function() {
                this.$refs.popupBody.focus();
            });
            // this.$refs.popup.open();
        },
        close() {
            const self = this;
            self.isVisible = false;
            // self.$refs.popup.close();
            setTimeout(() => {
                self.loading = false;
            }, 100);
        },
        setLoading(loading) {
            this.loading = loading;
        },
        _handler(confirmed) {
            this.callback(confirmed);
            if(!confirmed) {
                this.close();
            }
        }
    }
}
</script>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .popup-modal {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        z-index: 9999;
    }

    .window {
        background: #fff;
        border-radius: 5px;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
    }

    .btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ok-btn {
        color: red;
        text-decoration: underline;
        line-height: 2.5rem;
        cursor: pointer;
    }

    .cancel-btn {
        padding: 0.5em 1em;
        background-color: #d5eae7;
        color: #35907f;
        border: 2px solid #0ec5a4;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
    }
</style>